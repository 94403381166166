/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import storage, { keyNames } from '../../utils/storage';
import {
  TextField,
  MenuItem,
  Stack,
} from '@mui/material';

const LanguageButton = () => {
  const { set, get } = storage();
  const { i18n, t } = useTranslation();

  const [lang, setLang] = useState('pt');

  const changeLanguage = (lng: string) => {
    set(keyNames.lang, lng);
    setLang(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const langSaved = get(keyNames.lang);
    if (langSaved) setLang(langSaved);
  }, []);

  return (
    <Stack direction="row" gap="10px" alignItems="center">
      <TextField select value={lang} label={t('layout.language')} fullWidth>
        <MenuItem value="pt" onClick={() => changeLanguage('pt')}>Português BR</MenuItem>
        <MenuItem value="en" onClick={() => changeLanguage('en')}>English US</MenuItem>
      </TextField>
    </Stack>
  );
};

export default LanguageButton;
