/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useId, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRcode from 'react-qr-code';
import { BiQr, BiTrash, BiCloudDownload } from "react-icons/bi";
import storage, { keyNames } from '../../utils/storage';
import exportPDF from '../../utils/exportPDF';

type QRprops = {
  title: string,
  value: string,
}

export default function QrCode() {
  const { t } = useTranslation();
  const keyId = useId();
  const toPDF = useRef<HTMLElement | null>(null);
  const firstLoad = useRef(true);
  const { get, set, removeOne } = storage();
  const [qrList, setQrList] = useState<QRprops[]>([]);
  const [qrForm, setQrForm] = useState({ title: '', value: '' });

  useEffect(() => {
    const list = get(keyNames.qrCodes);
    if (!list) return;
    setQrList(list);
  }, []);

  const clearForm = () => {
    setQrForm({ title: '', value: '' });
  }

  const handleAdd = () => {
    if (qrList?.length) {
      setQrList((prev) => ([
        ...prev,
        qrForm,
      ]));
    } else {
      setQrList([qrForm]);
    }

    clearForm();
    firstLoad.current = false;
  }

  useEffect(() => {
    if (firstLoad.current) return;
    set(keyNames.qrCodes, qrList);
  }, [qrList]);

  const handleDownloadPDF = () => {
    exportPDF(toPDF.current)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setQrForm((prev: QRprops) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Box padding="25px 0 15px 0">
      <Typography variant="h5">{t('layout.createQr')}</Typography>
      <Box
        display="grid"
        gap="20px"
        gridTemplateColumns="repeat(3, 1fr)"
        padding="20px 0"
      >
        <TextField
          label={t('layout.qrTitle')}
          value={qrForm.title}
          name="title"
          onChange={handleChange}
        />
        <TextField
          label={t('layout.qrContent')}
          value={qrForm.value}
          name="value"
          onChange={handleChange}
          disabled={!qrForm.title}
        />
        <Button
          type="button"
          variant="contained"
          size="large"
          endIcon={<BiQr />}
          onClick={handleAdd}
          disabled={!qrForm.title || !qrForm.value}
        >
          {t('buttons.addQrCode')}
        </Button>
      </Box>
      <Box padding="20px 0" display="flex" justifyContent="space-between">
        <Button
          type="button"
          onClick={() => {
            removeOne(keyNames.qrCodes);
            setQrList([]);
          }}
          color="error"
          variant="outlined"
          endIcon={<BiTrash />}
        >
          {t('buttons.clearAll')}
        </Button>
        <Button onClick={handleDownloadPDF} disabled={!qrList?.length} type="button" color="success" variant="contained" endIcon={<BiCloudDownload />}>
          {t('buttons.downloadPdf')}
        </Button>
      </Box>

      <Box ref={toPDF} sx={{ background: '#fff' }} padding="20px" display="flex" gap="20px">
        {!qrList?.length ? (
          <Typography width="100%" variant="h6" textAlign="center" color="#000">{t('layout.noQrCode')}</Typography>
        ) : (
          <>
            {qrList.map((qr: QRprops) => (
              <Box
                key={keyId}
                padding="20px"
                sx={{ background: '#fff', width: '256px', boxSizing: 'border-box' }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                border="3px dashed #777"
              >
                <QRcode
                  value={qr.value}
                  bgColor="#fff"
                  fgColor="#000"
                  size={256}
                  style={{ width: '100%', height: 'auto' }}
                />
                <Typography component="code" variant="h5" color="#000" textAlign="center">{qr.title}</Typography>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}