import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  LinearProgress,
  Stack,
} from '@mui/material';

type props = {
  show: boolean;
}

export default function Loading({ show }: props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Backdrop
      open={show}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ width: '100%', height: '100vh' }}
      >
        <LinearProgress
          color="primary"
          sx={{
            width: '100%',
            background: 'transparent'
          }}
          title={t('layout.loading')}
        />
      </Stack>
    </Backdrop>
  )
}