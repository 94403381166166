/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import {
  FaSave,
  FaTrash,
  FaLockOpen,
  FaLink,
} from 'react-icons/fa';
import storage, { keyNames } from '../../utils/storage';
import { FetchedDataContext } from '../../context/fetchedData';
import Modal from '../../components/Modal';
import { toast, ToastItem } from 'react-toastify';
import api, { endpoints } from '../../services/api';
import LanguageButton from '../../components/LanguageButton';

import icons from '../../utils/icons';
import {
  GitlabGroupID,
  HandleChangeData,
} from '../../utils/entities';

import {
  HiOutlineExternalLink
} from 'react-icons/hi';

export default function Configurations(): JSX.Element {
  const { data, setData } = useContext(FetchedDataContext);
  const { t } = useTranslation();
  const { get, set, removeOne } = storage();
  const { toggl, gitlab } = api();
  const [tokens, setTokens] = useState({
    pivotal: '',
    gitlab: '',
    toggl: '',
    toggl_workspace: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [groupSelected, setGroupSelected] = useState('');

  function reloadOnCloseToast(id: string) {
    toast.onChange((payload: ToastItem) => {
      if (payload?.status === 'removed' && payload?.id === id) window.location.reload();
    });
  }

  useEffect(() => {
    const getTokens = get(keyNames.tokens);
    if (!getTokens) return;
    setTokens(getTokens);
    setGroupSelected(get(keyNames.gitlabGroupID));
  }, []);

  useEffect(() => {
    if (!tokens.gitlab) return;
    if (data.gitlabGroups.length > 0) return;
    gitlab(endpoints.gitlab.get.groups, {
      headers: {
        "PRIVATE-TOKEN": tokens.gitlab,
      }
    }).then((res) => {
      if (!res?.data) return;
      const groups = res.data.map((group: GitlabGroupID) => ({ name: group.name, id: group.id }));
      setData((prev: any) => ({ ...prev, gitlabGroups: groups }));
    }).catch(() => {
      toast.error(t('toast.services.gitlab.groupNotFound'), { toastId: 'gitlabgroupsfail' });
    })
  }, [tokens.gitlab])

  const handleChangeData = ({ key, value }: HandleChangeData) => {
    setTokens((prev) => ({ ...prev, [key]: value }));
  }

  const getTogglWorkspace = (token: string) => {
    if (!token) return;
    toggl(endpoints.toggl.get.me, {
      headers: {
        "Content-Type": "application/json"
      },
      auth: {
        username: token,
        password: 'api_token',
      }
    }).then((res) => {
      if (!res?.data?.default_workspace_id) return;
      handleChangeData({ key: 'toggl_workspace', value: res?.data?.default_workspace_id });
      toast.success(t('toast.services.toggl.workspaceDefined'), { toastId: 'workspace' });

    }).catch((err) => toast.error(t(`toast.errors.${err.response.status}`, { service: 'TOGGL' }), { toastId: 'workspaceFailed' }));
  }

  const saveData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    set(keyNames.tokens, tokens);
    set(keyNames.gitlabGroupID, groupSelected);
    set(keyNames.tokenChange, true);
    toast.success(t('toast.dataSaved'), { toastId: 'datasaved', pauseOnFocusLoss: false });
    reloadOnCloseToast('datasaved');
  }

  const clearAllData = () => {
    removeOne(keyNames.tokens);
    removeOne(keyNames.gitlabGroupID);
    setTokens((prev) => ({
      ...prev,
      pivotal: '',
      gitlab: '',
      toggl: '',
      toggl_workspace: '',
    }));
    setGroupSelected('');
    setShowModal(false);
    toast.success(t('toast.tokensWiped'), { toastId: 'wipeData', pauseOnFocusLoss: false });
    reloadOnCloseToast('wipeData');
  }


  return (
    <Box
      className="row pt-4"
      component="form"
      onSubmit={saveData}
    >
      <Box className="col-12">
        <Typography
          variant="h5"
          component="h1"
        >
          {t('layout.settings')}
        </Typography>
      </Box>

      <Box className="col-12">
        <Typography
          component="h2"
          marginTop="20px"
          border="1px solid #555"
          borderRadius="6px"
          padding="10px"
          textAlign="center"
        >
          <FaLockOpen />
          {` ${t('layout.tokens')}`}
        </Typography>
      </Box>
      <Box
        className="col-12 col-lg-6 col-xxl-4"
      >
        <TextField
          label="Pivotal"
          fullWidth
          onChange={(e) => handleChangeData({ key: 'pivotal', value: e.target.value })}
          value={tokens.pivotal}
          autoComplete="off"
        />
      </Box>
      <Box
        className="col-12 col-lg-6 col-xxl-4"
      >
        <TextField
          label="Gitlab"
          fullWidth
          onChange={(e) => handleChangeData({ key: 'gitlab', value: e.target.value })}
          value={tokens.gitlab}
          autoComplete="off"
        />
      </Box>
      <Box
        className="col-12 col-lg-6 col-xxl-4"
      >
        <TextField
          label={t('layout.groupIdGitlab')}
          select
          fullWidth
          autoComplete="off"
          required={Boolean(tokens.gitlab)}
          value={groupSelected}
          disabled={!tokens.gitlab}
          onChange={(res: { target: { value: string } }) => setGroupSelected(res.target.value)}
        >
          {
            data.gitlabGroups.map((op: { id: string, name: string }) => (
              <MenuItem
                key={op.id}
                value={op.id}
              >
                {op.name}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>
      <Box
        className="col-12 col-lg-6"
      >
        <TextField
          label="Toggl"
          fullWidth
          value={tokens.toggl}
          onChange={(e) => {
            handleChangeData({ key: 'toggl', value: e.target.value });
            getTogglWorkspace(e.target.value);
          }}
          autoComplete="off"
        />
      </Box>
      <Box
        className="col-12 col-lg-6"
      >
        <TextField
          label={t('layout.togglWorkspaceId')}
          fullWidth
          helperText={tokens.toggl_workspace === '' ? t('feedback.defaultWorkspace') : ""}
          value={tokens.toggl_workspace}
          onChange={(e) => handleChangeData({ key: 'toggl_workspace', value: e.target.value })}
          disabled={!tokens.toggl_workspace}
          required={Boolean(tokens.toggl)}
          autoComplete="off"
        />
      </Box>
      <Box className="col-12 col-lg-6">
        <LanguageButton />
      </Box>

      <Stack
        className="col-12"
        direction="row"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          size="large"
          color="error"
          startIcon={<FaTrash />}
          onClick={() => setShowModal(true)}
          disabled={!get(keyNames.tokens)?.pivotal && !get(keyNames.tokens)?.gitlab}
        >
          {t('buttons.clearAll')}
        </Button>
        <Button
          variant="contained"
          size="large"
          startIcon={<FaSave />}
          type="submit"
        >
          {t('buttons.save')}
        </Button>
      </Stack>

      <Box className="col-12" id="links">
        <Typography
          component="h2"
          marginTop="20px"
          marginBottom="20px"
          border="1px solid #555"
          borderRadius="6px"
          padding="10px"
          textAlign="center"
        >
          <FaLink />
          {` ${t('layout.links')}`}
        </Typography>
        <Stack
          className="col-12 pb-3"
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Button
            href="https://gitlab.com/-/user_settings/personal_access_tokens"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<HiOutlineExternalLink />}
            color="primary"
            startIcon={
              <img
                src={icons.gitlab}
                alt="Logo gitlab"
                width="auto"
                height="20px"
              />
            }
          >
            Gitlab Token
          </Button>
          <Button
            href="https://www.pivotaltracker.com/profile"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<HiOutlineExternalLink />}
            color="primary"
            startIcon={
              <img
                src={icons.pivotal}
                alt="Logo Pivotal"
                width="auto"
                height="20px"
              />
            }
          >
            Pivotal Token
          </Button>
          <Button
            href="https://track.toggl.com/profile"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<HiOutlineExternalLink />}
            color="primary"
            startIcon={
              <img
                src={icons.toggl}
                alt="Logo Toggl"
                width="auto"
                height="20px"
              />
            }
          >
            Toggl Token
          </Button>
        </Stack>
      </Box>
      <Modal
        show={showModal}
        title={t('layout.clearTokensTitle')}
        text={t('layout.clearTokensText')}
        confirmLabel={t('buttons.erase')}
        cancelLabel={t('buttons.close')}
        onConfirm={() => clearAllData()}
        onCancel={() => setShowModal(false)}
      />
    </Box>
  )
}